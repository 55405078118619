import React from "react"
import { useLocation } from '@reach/router';
import { IntlContextConsumer, changeLocale } from '@intractive/gatsby-plugin-react-intl'
import { Link } from "gatsby"

const enFlag = (
<svg className="flag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 30">
    <clipPath id="s">
        <path d="M0,0v30h50v-30z"/>
    </clipPath>
    <clipPath id="t">
        <path d="M25,15h25v15zv15h-25zh-25v-15zv-15h25z"/>
    </clipPath>
    <g clip-path="url(#s)">
     <path d="M0,0v30h50v-30z" fill="#012169"/>
     <path d="M0,0 50,30M50,0 0,30" stroke="#fff" stroke-width="6"/>
     <path d="M0,0 50,30M50,0 0,30" clip-path="url(#t)" stroke="#C8102E" stroke-width="4"/>
     <path d="M-1 11h22v-12h8v12h22v8h-22v12h-8v-12h-22z" fill="#C8102E" stroke="#FFF" stroke-width="2"/>
    </g>
</svg>);

const nlFlag = (<svg className="flag" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 6">
  <rect fill="#1b448c" width="9" height="6"/>
  <rect fill="#FFF" width="9" height="4"/>
  <rect fill="#b01623" width="9" height="2"/>
</svg>);


const languageName = {
  'en': "English",
  'nl-NL': "Nederlands",
}

function getImageByCode(code) {
    return code === 'en' ? enFlag : nlFlag
}

const Language = () => {
    const location = useLocation();
    return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => {
        const otherLocale = (currentLocale === 'en') ? 'nl-NL' : 'en';
        const otherLocaleUrl = function() {
            if (currentLocale === 'en') {
                return location.pathname.substring(3)
            } else {
                return "/en" + location.pathname
            }
        }
        return (<Link to={otherLocaleUrl()}>
                    {getImageByCode(otherLocale)} <span className="flag-text">{languageName[otherLocale]}</span>
                </Link>)
      }}
    </IntlContextConsumer>
)}

export default Language
