import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import Language from '../../Language'

const Header = ({ siteTitle }) => {
  const intl = useIntl();
  return (
    <div className="yellow-bg">
        <div className="row">
            <header className="header">
                <div className="xlarge-3 large-2 medium-2 small-6 columns center-vertical">
                    <Link className="logo" to="/" title="STIP Delft" rel="start">
                        <img src="/images/logo/stip_logo_2021.svg"/>
                    </Link>
                </div>
                <div className="xlarge-9 large-10 medium-10 small-6 columns center-vertical">
                    <ul className="hide-for-small nav-items spread-horizontal">
                        <li>
                            <Link to="/actueel/" activeClassName="active" partiallyActive={true}>
                                {intl.formatMessage({id: "Actueel"})}
                            </Link>
                        </li>
                        <li>
                            <Link to="/mensen/" activeClassName="active" partiallyActive={true}>
                                {intl.formatMessage({id: "Mensen"})}
                            </Link>
                        </li>
                        <li>
                            <Link to="/speerpunten/" activeClassName="active" partiallyActive={true}>
                                {intl.formatMessage({id: "Standpunten"})}
                            </Link>
                        </li>
                        <li>
                            <Link to="/over-stip/" activeClassName="active" partiallyActive={true}>
                                {intl.formatMessage({id: "Over STIP"})}
                            </Link>
                        </li>
                        <li>
                            <div className="flag">
                                <Language />       
                            </div>
                        </li>
                        <li>
                            <Link id="meedoen" to="/meedoen/" activeClassName="active" partiallyActive={true}>
                                {intl.formatMessage({id: "Doe mee!"})}
                            </Link>
                        </li>
                    </ul>
                    <div className="mobile-menu show-for-small">
                        <Menu right className="menu-button">
                            <Link id="actueel" to="/actueel/" className="bm-item" activeClassName="active">
                                {intl.formatMessage({id: "Actueel"})}
                            </Link>
                            <div className="mobile-divider"></div>
                            <Link id="mensen" to="/mensen/" className="bm-item" activeClassName="active">{intl.formatMessage({id: "Mensen"})}</Link>
                            <ul className='submenu'>
                                <li><Link to="/mensen/lijst/" activeClassName="active">{intl.formatMessage({id: "Lijst"})}</Link></li>
                                <li><Link to="/mensen/oud-fractie/" activeClassName="active">{intl.formatMessage({id: "Oud-Fractie"})}</Link></li>
                                <li><Link to="/mensen/oud-bestuur/" activeClassName="active">{intl.formatMessage({id: "Oud-Bestuur"})}</Link></li>
                            </ul>
                            <div className="mobile-divider"></div>
                            <Link id="speerpunten" to="/speerpunten/" className="bm-item" activeClassName="active">{intl.formatMessage({id: "Standpunten"})}</Link>
                            <ul className='submenu'>
                                <li><Link to="/speerpunten/verkiezingsprogramma/" activeClassName="active">{intl.formatMessage({id: "Verkiezingsprogramma"})}</Link></li>
                                <li><Link to="/speerpunten/moties/" activeClassName="active">{intl.formatMessage({id: "Moties"})}</Link></li>
                                <li><Link to="/speerpunten/visie-op-delft/" activeClassName="active">{intl.formatMessage({id: "Visie op Delft"})}</Link></li>
                            </ul>
                            <div className="mobile-divider"></div>
                            <Link id="over-stip" to="/over-stip/" className="bm-item" activeClassName="active">{intl.formatMessage({id: "Over STIP"})}</Link>
                            <ul className='submenu'>
                                <li><Link to="/over-stip/contact/" activeClassName="active">{intl.formatMessage({id: "Contact"})}</Link></li>
                                <li><Link to="/over-stip/geschiedenis/" activeClassName="active">{intl.formatMessage({id: "Geschiedenis"})}</Link></li>
                                <li><Link to="/over-stip/de-gemeenteraad/" activeClassName="active">{intl.formatMessage({id: "Gemeenteraad"})}</Link></li>
                                <li><Link to="/over-stip/lid-worden/" activeClassName="active">{intl.formatMessage({id: "Lid worden"})}</Link></li>
                            </ul>
                            <li><Language /></li>
                            <div className="mobile-divider"></div>
                            <Link id="meedoenmobile" to="/meedoen/" className="bm-item" activeClassName="active">{intl.formatMessage({id: "Doe mee!"})}</Link>
                        </Menu>
                    </div>
                </div>
      {/*<div className="large-3 medium-3 small-6 columns">
          <div className="logo-container">
            <div className="logo-wrapper">
              <Link className="logo" to="/" title="STIP Delft" rel="start"><img src="/images/logo/stip_logo_2021.svg"/></Link>
            </div>
          </div>
        </div>
        <nav role="navigation" className="nav-main large-9 medium-9 small-6 max-height">
          <div className="nav-container">
          </div>
        </nav>*/}
            </header>
        </div>
    </div>
)}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
