import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'

import Header from './Header'
import Footer from './Footer'
import './old-styles.css'

const Layout = ({ children }) => {
    const intl = useIntl();
    const console_script = `
                var c = [
                  'font-size: 60px;',
                  'background-color: #fde600;',
                  'color: #e3171d;',
                  'font-weight: 800;'
                 ].join(' ');
                var t = [
                  'font-size: 60px;',
                  'background-color: #fde600;',
                  'color: #333333;',
                  'font-weight: 800;'
                 ].join(' ');
                var m = [
                  'font-size: 24px;',
                  'background-color: #fde600;',
                  'color: #333333;',
                  'font-weight: 400;'
                 ].join(' ');
                 console.log("%c●%cSTIP\\n%c` + intl.formatMessage({id: "Wij zijn dé partij met technische kennis! Op https://www.stipdelft.nl/portefeuilles/bestuur-en-dienstverlening/ kan je vinden hoe wij ons inzetten voor betere digitale dienstverlening."}) + `", c, t, m);`
    return (
      <>
        <Helmet
          title={intl.formatMessage({id: "STIP"})}
          meta={[
          //   { name: 'description', content: 'Sample' },
             { name: 'keywords', content: intl.formatMessage({id: "keywords"}) },
          ]}
        >
        <html lang={intl.formatMessage({id: "lang"})} className="js backgroundsize svg" />
        <script>{console_script}</script>
        </Helmet>
        <Header siteTitle={intl.formatMessage({id: "STIP"})} />
        {children}
        <Footer />
      </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
