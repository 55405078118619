import { useIntl, Link } from '@intractive/gatsby-plugin-react-intl'
import React from 'react'


const Footer = () => {
  const intl = useIntl();
  return (
    <footer className="row footer">
      <div className="large-12 columns">
        <div className="horizontal-line-footer">
          <div className="row">

            <div className="large-2 medium-2 columns hide-for-small-only">
              <div className="logo-container">
                <div className="logo-wrapper footer-logo">
                  <Link to="/" className="logo" title={intl.formatMessage({ id: "STIP Delft" })}>
                    <span className="logo-footer-text">
                      {intl.formatMessage({ id: "STIP Delft" })}
                    </span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="large-6 medium-6 columns small-12">
              <div className="footer-menu">
                <Link className="footer-menu-item" to="/anbi/">{intl.formatMessage({ id: "ANBI" })}</Link>
                <Link className="footer-menu-item" to="/privacy/">{intl.formatMessage({ id: "Privacy" })}</Link>
                <a className="footer-menu-item" href="https://assets.ctfassets.net/xr7ntapvnai4/73gfjVk9EYQOkclCfUzj8/97e16cb4be7d81dd0d40892e30d11597/Declaratieformulier-STIP.xlsx">{intl.formatMessage({ id: "Declaratieformulier" })}</a>
                <Link className="footer-menu-item" to="/over-stip/contact/">{intl.formatMessage({ id: "Contact" })}</Link>
              </div>
            </div>

            <div className="large-4 medium-4 columns small-12">
              <div className="footer-menu">
                <a className="footer-menu-item" href="https://www.facebook.com/stipdelft" target="_blank" rel="noopener noreferrer"><img className="social-icon" src="/images/facebook.svg" alt="Facebook" width="20" height="20" /></a>
                <a className="footer-menu-item" href="https://twitter.com/stipdelft" target="_blank" rel="noopener noreferrer"><img className="social-icon" src="/images/twitter.svg" alt="Twitter" width="20" height="20" /></a>
                <a className="footer-menu-item" href="https://instagram.com/stipdelft" target="_blank" rel="noopener noreferrer"><img className="social-icon" src="/images/instagram.svg" alt="Instagram" width="20" height="20" /></a>
                <a className="footer-menu-item" href="https://www.snapchat.com/add/STIPdelft1993" target="_blank" rel="noopener noreferrer"><img className="social-icon" src="/images/snapchat.svg" alt="Snapchat" width="20" height="20" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
